// TODO: consider using https://github.com/github/webauthn-json
global.Buffer = global.Buffer || require('buffer').Buffer;

const base64url = require('base64url');

function decodePublicKeyOptions(publicKeyOptions) {
  // TODO: the encoding/decoding back and forth is rather annoying
  publicKeyOptions.publicKey.challenge = base64url.toBuffer(
    publicKeyOptions.publicKey.challenge
  );
  if ('user' in publicKeyOptions.publicKey) {
    publicKeyOptions.publicKey.user.id = base64url.toBuffer(
      publicKeyOptions.publicKey.user.id
    );
  }
  return publicKeyOptions;
}

// Sending the data back to the server isn't straight-forward, see
// https://github.com/w3c/webauthn/issues/1683
// This function is taken from
// https://github.com/mkalioby/django-passkeys/blob/main/passkeys/static/passkeys/js/helpers.js
function publicKeyCredentialsToJSON(pubKeyCred) {
  if (pubKeyCred instanceof Array) {
    let arr = [];
    for (let i of pubKeyCred) {
      arr.push(publicKeyCredentialsToJSON(i));
    }
    return arr;
  }

  if (pubKeyCred instanceof ArrayBuffer) {
    return base64url.encode(pubKeyCred);
  }

  if (pubKeyCred instanceof Object) {
    let obj = {};
    for (let key in pubKeyCred) {
      obj[key] = publicKeyCredentialsToJSON(pubKeyCred[key]);
    }
    return obj;
  }

  return pubKeyCred;
}

window.addEventListener('DOMContentLoaded', event => {
  let register_passkey = document.getElementById('create-passkey');
  if (register_passkey) {
    // Derived from https://web.dev/passkey-registration/
    // Availability of `window.PublicKeyCredential` means WebAuthn is usable.
    // `isUserVerifyingPlatformAuthenticatorAvailable` means the feature detection is usable.
    // `isConditionalMediationAvailable` means the feature detection is usable.
    if (
      window.PublicKeyCredential &&
      PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable &&
      PublicKeyCredential.isConditionalMediationAvailable
    ) {
      // Check if user verifying platform authenticator is available.
      Promise.all([
        PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
        PublicKeyCredential.isConditionalMediationAvailable()
      ]).then(results => {
        if (results.every(r => r === true)) {
          register_passkey.style.display = 'block';
          let compat_message = document.getElementById(
            'create-passkey-incompatible'
          );
          if (compat_message) {
            compat_message.style.display = 'none';
          }
        } else {
          register_passkey.style.display = 'none';
        }
      });
    } else {
      register_passkey.style.display = 'none';
    }

    register_passkey.addEventListener('click', async function() {
      var encoder = new TextEncoder();
      const options = await fetch('/en/account/passkeys/register/begin/', {
        method: 'POST'
      });
      var publicKeyOptions = await options.json();
      console.log(publicKeyOptions.publicKey.challenge);
      const decodedPublicKeyOptions = decodePublicKeyOptions(publicKeyOptions);
      const credentials = await navigator.credentials.create({
        publicKey: decodedPublicKeyOptions.publicKey
      });
      // Encode and send the credential to the server for verification.
      data = publicKeyCredentialsToJSON(credentials);
      json_data = JSON.stringify(data);
      await fetch('/en/account/passkeys/register/', {
        method: 'POST',
        body: json_data
      });
      location.reload();
    });
  }
});

async function getCredentials() {
  let login_passkey = document.getElementById('id_passkeys');
  // Derived from https://web.dev/passkey-form-autofill/
  // Availability of `window.PublicKeyCredential` means WebAuthn is usable.
  if (login_passkey) {
    if (
      window.PublicKeyCredential &&
      PublicKeyCredential.isConditionalMediationAvailable
    ) {
      // Check if conditional mediation is available.
      const isCMA = await PublicKeyCredential.isConditionalMediationAvailable();
      if (isCMA) {
        const options = await fetch(
          '/en/account/passkeys/authenticate/begin/',
          {
            method: 'POST'
          }
        );
        const publicKeyOptions = await options.json();
        const decodedPublicKeyOptions = decodePublicKeyOptions(
          publicKeyOptions
        );
        console.log(publicKeyOptions);
        const abortController = new AbortController();
        await navigator.credentials
          .get({
            publicKey: decodedPublicKeyOptions.publicKey,
            signal: abortController.signal,
            mediation: 'conditional'
          })
          .then(function(credentials) {
            const authentication_data = JSON.stringify(
              publicKeyCredentialsToJSON(credentials)
            );
            console.log(authentication_data);
            login_passkey.value = authentication_data;
            login_passkey.form.submit();
          })
          .catch(function(error) {
            if (error.name == 'AbortError') {
              console.log('Auth aborted.');
              getCredentials();
            }
          });
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', async () => {
  getCredentials();
});

window['publicKeyCredentialsToJSON'] = publicKeyCredentialsToJSON;
window['decodePublicKeyOptions'] = decodePublicKeyOptions;
